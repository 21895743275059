import { ApiResponse, apiClient } from "./client";
import { getBlobUrl } from "@/utils/apiHelpers";

export type ValidateAndImportDto = {
  organizationId: number;
  caseTemplateId: number;
  file: File;
};

export type ValidateAndImportResult = {
  success: boolean;
  errors: Record<string, string[]>;
  totalRows: number;
  importId: string;
};

export enum BatchImportStatus {
  Pending = "Pending",
  InProgress = "InProgress",
  Completed = "Completed",
  Failed = "Failed",
  Locked = "Locked"
}

export type ImportProgress = {
  status: BatchImportStatus;
  totalRows: number;
  processedRows: number;
  progress: number;
  errors: Record<string, string[]>;
};

export const downloadImportTemplate =
  (caseTemplateId: number) => async (): Promise<ApiResponse<string>> => {
    const response = await apiClient.get(
      `batch/case-templates/${caseTemplateId}/download-import-template`,
      {
        responseType: "blob"
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      const a = document.createElement("a");
      const url = getBlobUrl(
        response,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      a.href = url;
      a.target = "_blank";
      a.download = `template_${caseTemplateId}.xlsx`;
      a.click();
      return { result: url };
    }
  };

export const validateImportFile =
  (data: ValidateAndImportDto) =>
  async (): Promise<ApiResponse<ValidateAndImportResult>> => {
    if (data.organizationId === 0 || data.caseTemplateId === 0 || !data.file) {
      return {
        error: {
          message:
            "Please provide organizationId, caseTemplateId and file to validate"
        }
      };
    }

    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("organizationId", data.organizationId.toString());
    formData.append("caseTemplateId", data.caseTemplateId.toString());
    formData.append("validate", "true");

    const response = await apiClient.post(`batch/case-templates/imports`, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: formData,
      nonLoggedErrorCodes: [400]
    });

    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as ValidateAndImportResult };
    }
  };

export const createImport =
  (data: ValidateAndImportDto) =>
  async (): Promise<ApiResponse<ValidateAndImportResult>> => {
    if (data.organizationId === 0 || data.caseTemplateId === 0 || !data.file) {
      return {
        error: {
          message:
            "Please provide organizationId, caseTemplateId and file to import"
        }
      };
    }

    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("organizationId", data.organizationId.toString());
    formData.append("caseTemplateId", data.caseTemplateId.toString());

    const response = await apiClient.post(`batch/case-templates/imports`, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: formData,
      nonLoggedErrorCodes: [400]
    });

    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as ValidateAndImportResult };
    }
  };

export const processImport =
  (importId: number) => async (): Promise<ApiResponse<ImportProgress>> => {
    const response = await apiClient.post(
      `batch/case-templates/imports/${importId}`
    );

    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as ImportProgress };
    }
  };

export const downloadImportResults =
  (importId: number) => async (): Promise<ApiResponse<string>> => {
    const response = await apiClient.get(
      `batch/case-templates/imports/${importId}/results`,
      {
        responseType: "blob"
      }
    );

    if (response.hasError) {
      return { error: response.error };
    } else {
      const url = getBlobUrl(
        response,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      const a = document.createElement("a");
      a.href = url;
      a.download = `import_results_${importId}.xlsx`;
      a.click();
      return { result: url };
    }
  };
