import { OrganizationSessionData } from "./accountApi";
import { ApiResponse, apiClient } from "./client";
import { Membership } from "./membershipApi";
import { Address } from "./customerApi";
import { MonthStats, TemplateStats } from "./types";

export const createOrganization =
  (data: CreateOrganizationData) =>
  async (): Promise<ApiResponse<OrganizationSessionData>> => {
    const response = await apiClient.post(`/organizations`, { data });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as OrganizationSessionData };
    }
  };

export const getOrganizations =
  (query: string) => async (): Promise<ApiResponse<FilteredOrganizations>> => {
    const response = await apiClient.get(`/organizations/filter?${query}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as FilteredOrganizations };
    }
  };

export const getOrganizationsAsCsv =
  (query: string) => async (): Promise<ApiResponse<Blob>> => {
    const response = await apiClient.get(`/organizations/filter?${query}`, {
      responseType: "blob"
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Blob };
    }
  };

export const getOrganizationUser =
  (userId: string, organizationId: number) =>
  async (): Promise<ApiResponse<OrganizationUser>> => {
    const response = await apiClient.get(
      `organizations/${organizationId}/users/${userId}`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as OrganizationUser };
    }
  };

export const setOrganizationUserRole =
  (userId: string, organizationId: number, role: string) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.put(`organizations/users`, {
      data: { organizationId, userId, role }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const uploadOrganizationLogo =
  (organizationId: number, uploadedFile: File) =>
  async (): Promise<ApiResponse<null>> => {
    const formData = new FormData();
    formData.append("uploadedFile", uploadedFile, uploadedFile.name);
    const response = await apiClient.post(
      `organizations/${organizationId}/logo`,
      { data: formData }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const removeOrganizationLogo =
  (organizationId: number) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.delete(
      `organizations/${organizationId}/logo`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const getOrganization =
  (id: number) => async (): Promise<ApiResponse<Organization>> => {
    const response = await apiClient.get(`/organizations/${id}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Organization };
    }
  };

export const saveOrganization =
  (organization: Organization) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.patch(`/organizations`, {
      data: organization
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const removeUserFromOrganization =
  (userId: string, organizationId: number) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.delete(
      `/organizations/${organizationId}/users/${userId}`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const deleteOrganization =
  (id: number, currentPassword: string, currentOrganizationName: string) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`/organizations/${id}/delete`, {
      data: {
        currentPassword,
        currentOrganizationName
      }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const getTemplateStats =
  (organizationId: number, dateFrom: string, dateUntil: string) =>
  async (): Promise<ApiResponse<TemplateStats[]>> => {
    const response = await apiClient.get(
      `/organizations/${organizationId}/stats/templates?from=${dateFrom}&until=${dateUntil}`
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as TemplateStats[] };
  };

export const getTemplateStatsAsCsv =
  (organizationId: number, dateFrom: string, dateUntil: string) =>
  async (): Promise<ApiResponse<Blob>> => {
    const response = await apiClient.get(
      `/organizations/${organizationId}/stats/templates?from=${dateFrom}&until=${dateUntil}&export=true`,
      { responseType: "blob" }
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as Blob };
  };

export const getDashboardStats =
  (organizationId: number) =>
  async (): Promise<ApiResponse<DashboardStats>> => {
    const response = await apiClient.get(
      `/organizations/${organizationId}/stats/dashboard`
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as DashboardStats };
  };

export type DashboardStats = {
  start: { year: number; month: number };
  until: { year: number; month: number };
  casesPerMonth: MonthStats[];
  totalCasesCreated: number;
  payedTemplatesPerMonth: MonthStats[];
  totalNumberOfPayedTemplates: number;
};

// Types
export type Organization = {
  id: number;
  name: string;
  created?: Date;
  customerId: number;
  customerName: string;
  logo?: number[];
  logoExtension?: string;
  createPublicCases: boolean;
  redactConcludedMonths: number;
  redactDraftMonths: number;
  allowBatchImports?: boolean;
};

export const getNumberOfCases =
  (organizationId: number) =>
  async (): Promise<ApiResponse<NumberOfCasesResult>> => {
    const response = await apiClient.get(
      `/organizations/${organizationId}/numberofcases`
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as NumberOfCasesResult };
  };

export type OrganizationRole = "Owner" | "Admin" | "User";

export type OrganizatoonCaseStats = {
  start: { year: number; month: number };
  until: { year: number; month: number };
  casesPerMonth: MonthStats[];
  totalCasesCreated: number;
};

export type UserOrganization = {
  organizationId: number;
  organizationName: string;
  role: OrganizationRole;
  isOnlyUserInRole: boolean;
};

export type CustomerMembership = Membership & {
  number: string;
};

export type RemoveOrganizationData = {
  currentPassword: string;
};

export type CreateOrganizationData = {
  organizationName: string;

  customerName: string;
  organizationNumber: string;
  invoiceReference: string;
  invoiceEmail: string;
  customerPhoneNumber: string;
  customerAddress: Address;
  invoiceAddress: Address | null;
  useExistingCustomer: boolean;
  existingCustomerId: number;
  createPublicCases: boolean;
  membership: CustomerMembership | null;
};

export type OrganizationUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  organizations: UserOrganization[];
};

export type FilteredOrganizations = {
  records: FilteredOrganizationItem[];
  page: number;
  pageSize: number;
  totalRecords: number;
};

export type FilteredOrganizationItem = {
  id: number;
  name: string;
  customerId: number;
  customerName: string;
  organizationNumber: string;
  subscription: string;
};

export type NumberOfCasesResult = {
  count: number;
};
