import { ApiResponse, apiClient } from "./client";
import { Membership } from "./membershipApi";
import { TemplateStats, MonthStats } from "./types";

export const getCustomer =
  (id: number) => async (): Promise<ApiResponse<Customer>> => {
    const response = await apiClient.get(`/customers/${id}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Customer };
    }
  };

export const getCustomers =
  (query: string) => async (): Promise<ApiResponse<FilteredCustomers>> => {
    const response = await apiClient.get(`/customers/filter?${query}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as FilteredCustomers };
    }
  };

export const getCustomersAsCsv =
  (query: string) => async (): Promise<ApiResponse<Blob>> => {
    const response = await apiClient.get(`/customers/filter?${query}`, {
      responseType: "blob"
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Blob };
    }
  };

export const getUserCustomers =
  () => async (): Promise<ApiResponse<Customer[]>> => {
    const response = await apiClient.get("/customers");
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Customer[] };
    }
  };

export const saveCustomer =
  (customer: Customer) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.patch(`/customers`, {
      data: customer
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const revokeApiKey =
  (customerId: number, id: number) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.delete(
      `/customers/${customerId}/api-keys/${id}`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const createApiKey =
  (customerId: number, name: string) =>
  async (): Promise<ApiResponse<string | null>> => {
    const response = await apiClient.post(`/customers/${customerId}/api-keys`, {
      data: { name }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as string };
    }
  };

export const getApiKeys =
  (customerId: number) => async (): Promise<ApiResponse<ApiKeyResponse[]>> => {
    const response = await apiClient.get(`/customers/${customerId}/api-keys`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as ApiKeyResponse[] };
    }
  };

export const getApiUrl = () => async (): Promise<ApiResponse<string>> => {
  const response = await apiClient.get(`/customers/api-url`);
  if (response.hasError) {
    return { error: response.error };
  } else {
    return { result: response.data as string };
  }
};

export const getCustomerOrganizations =
  (customerId: number) =>
  async (): Promise<ApiResponse<CustomerOrganization[]>> => {
    const response = await apiClient.get(
      `/customers/${customerId}/organizations`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as CustomerOrganization[] };
    }
  };

export const getCustomerUsers =
  (customerId: number) => async (): Promise<ApiResponse<CustomerUser[]>> => {
    const response = await apiClient.get(`/customers/${customerId}/users`);
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as CustomerUser[] };
  };

export const getCustomerAdmins =
  (customerId: number) => async (): Promise<ApiResponse<CustomerUser[]>> => {
    const response = await apiClient.get(`/customers/${customerId}/admins`);
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as CustomerUser[] };
  };

export const addCustomerAdmin =
  (customerId: number, userId: string) =>
  async (): Promise<ApiResponse<string | null>> => {
    const response = await apiClient.post(`/customers/${customerId}/admins`, {
      data: { id: userId }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as string };
    }
  };

export const removeCustomerAdmin =
  (customerId: number, userId: string) =>
  async (): Promise<ApiResponse<string | null>> => {
    const response = await apiClient.delete(
      `/customers/${customerId}/admins/${userId}`,
      {}
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as string };
    }
  };

export const setCustomerOwner =
  (customerId: number, userId: string) =>
  async (): Promise<ApiResponse<string | null>> => {
    const response = await apiClient.patch(`/customers/${customerId}/owner`, {
      data: { id: userId }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as string };
    }
  };

export const setCustomerQuotaAsSysAdmin =
  (customerId: number, command: UpdateSubscriptionCommand) =>
  async (): Promise<ApiResponse<string | null>> => {
    const response = await apiClient.patch(
      `/customers/${customerId}/set-quota`,
      {
        data: command
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as string };
    }
  };

export const listAvailableSubscriptions =
  (customerId: number) =>
  async (): Promise<ApiResponse<SubscriptionPlan[]>> => {
    const response = await apiClient.get(
      `/customers/${customerId}/list-available-subscriptions`
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as SubscriptionPlan[] };
  };

export const getCustomerSubscription =
  (customerId: number) =>
  async (): Promise<ApiResponse<CustomerSubscriptionResult>> => {
    const response = await apiClient.get(
      `/customers/${customerId}/subscription`
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as CustomerSubscriptionResult };
  };

export const setCustomerSubscription =
  (customerId: number, subscriptionPlanId: number) =>
  async (): Promise<ApiResponse<CustomerSubscriptionResult>> => {
    const response = await apiClient.post(
      `/customers/${customerId}/subscription`,
      { data: { customerId, subscriptionPlanId } }
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as CustomerSubscriptionResult };
  };

export const cancelCustomerSubscription =
  (customerId: number) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.delete(
      `/customers/${customerId}/subscription`
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: null };
  };

export const getTransactionDetails =
  (transactionId: number) =>
  async (): Promise<ApiResponse<TransactionDetails>> => {
    const response = await apiClient.get(
      `/customers/transactions/${transactionId}`
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as TransactionDetails };
  };

export const getCustomerTransactions =
  (customerId: number, page: number, pageSize: number) =>
  async (): Promise<ApiResponse<PagedResponse<CustomerTransaction>>> => {
    const response = await apiClient.get(
      `/customers/${customerId}/transactions?page=${page}&pageSize=${pageSize}`
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as PagedResponse<CustomerTransaction> };
  };

export const getTemplateStats =
  (customerId: number, dateFrom: string, dateUntil: string) =>
  async (): Promise<ApiResponse<TemplateStats[]>> => {
    const response = await apiClient.get(
      `/customers/${customerId}/stats/templates?from=${dateFrom}&until=${dateUntil}`
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as TemplateStats[] };
  };

export const getTemplateStatsAsCsv =
  (customerId: number, dateFrom: string, dateUntil: string) =>
  async (): Promise<ApiResponse<Blob>> => {
    const response = await apiClient.get(
      `/customers/${customerId}/stats/templates?from=${dateFrom}&until=${dateUntil}&export=true`,
      { responseType: "blob" }
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as Blob };
  };

export const getDashboardStats =
  (customerId: number) => async (): Promise<ApiResponse<DashboardStats>> => {
    const response = await apiClient.get(
      `/customers/${customerId}/stats/dashboard`
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as DashboardStats };
  };

export const setCustomerSubscriptionAsAdmin =
  (
    customerId: number,
    isCustomPlan: boolean,
    startDate: Date,
    planId?: number,
    planName?: string,
    planPrice?: number,
    planPins?: number
  ) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.patch(
      `/customers/${customerId}/set-subscription`,
      {
        data: {
          customerId,
          isCustomPlan,
          startDate,
          planId,
          planName,
          planPrice,
          planPins
        }
      }
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: null };
  };

export const deleteCustomer =
  (id: number, currentPassword: string, currentCustomerName: string) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`/customers/${id}/delete`, {
      data: {
        currentPassword,
        currentCustomerName
      }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export type DashboardStats = {
  start: { year: number; month: number };
  until: { year: number; month: number };
  casesPerMonth: MonthStats[];
  totalCasesCreated: number;
  payedTemplatesPerMonth: MonthStats[];
  totalNumberOfPayedTemplates: number;
};

export type Address = {
  street: string;
  city: string;
  zipCode: string;
  country: string;
};

export type CustomerMembership = Membership & {
  number: string;
};

export type Customer = {
  id: number;
  name: string;
  created?: Date;
  organizationNumber: string;
  phoneNumber: string;
  remainingQuota: number;
  blocked: boolean;
  invoiceEmail: string;
  invoiceReference: string;
  invoiceGLNReference?: string;
  address: Address;
  invoiceAddress: Address | null;
  membership: CustomerMembership | null;
};

export type UpdateSubscriptionCommand = {
  quota: number;
};

export type ApiKeyResponse = {
  id: number;
  name: string;
  created: string;
};

export type CustomerOrganization = {
  id: number;
  name: string;
  numberOfCases: number;
};

export type CustomerUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};

export type FilteredCustomers = {
  records: FilteredCustomerItem[];
  page: number;
  pageSize: number;
  totalRecords: number;
};

export type FilteredCustomerItem = {
  id: number;
  name: string;
  organizationNumber: string;
  membership: string;
  subscription: string;
  remainingQuota: number;
  blocked: boolean;
};

export type SubscriptionPlanType = "Standard" | "Custom";

export type SubscriptionPlan = {
  id: number;
  name: string;
  description: string;
  standardPrice: number;
  memberPrice: number;
  saveUpTo: number;
  pins: number;
  type: SubscriptionPlanType;
};

export type CustomerSubscriptionResult = {
  customerIsBlocked: boolean;
  currentSubscription?: SubscriptionPlan;
  nextSubscription?: SubscriptionPlan;
  startDate: Date;
  renewalDate: Date;
  membership: string;
  hasMembership: boolean;
  currentQuota: number;
};
export type CustomerTransactionType =
  | "Information"
  | "SubscriptionPurchase"
  | "SubscriptionRenewal"
  | "QuotaPurchase";
export type CustomerTransaction = {
  id: number;
  firstName?: string;
  lastName?: string;
  date: Date;
  description: string;
  type: CustomerTransactionType;
};

export type TransactionDetails = {
  id: number;
  amount: number;
  date: Date;
  reference: string;
  description: string;
  paymentType: string;
  transactionType: string;
  customerAddressStreet: string;
  customerAddressCity: string;
  customerAddressZipCode: string;
  customerId: number;
  customerName: string;
  customerOrganizationNumber: string;
  invoiceReference: string;
  userId: string;
  userFirstName: string;
  userLastName: string;
};

export type PagedResponse<T> = {
  records: T[];
  page: number;
  pageSize: number;
  totalRecords: number;
};
